import { useContext } from "react";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { formatMonthForArabicCalendar } from "app/utils/utils";
import { FormattedDate } from "react-intl";

const CalendarMonthHeadline = ({ yearValue, monthValue }) => {
	const { shop } = useContext(AppGlobalsContext);

	const dateForMonth = new Date(yearValue, monthValue, 15, 15, 0, 0); // 15, 15, 0, 0 to avoid bug on win7 ie11 month shift

	if (isNaN(dateForMonth.getTime())) {
		return null;
	}

	return shop.includes("ar") ? (
		`${yearValue} ${formatMonthForArabicCalendar(shop, dateForMonth)}`
	) : (
		<FormattedDate timeZone="UTC" value={dateForMonth} month="long" year="numeric" />
	);
};

CalendarMonthHeadline.propTypes = {
	yearValue: PropTypes.number,
	monthValue: PropTypes.number,
};

export default CalendarMonthHeadline;
